import React from "react";
import {useDispatch} from "react-redux";
import {API_BASE_URL, LOADERS_BASE_PATH, TOKEN} from "../../../constants";
import axios from "axios";
import {message, Upload} from "antd";
import {Icon} from "@iconify/react";

const { Dragger } = Upload;

const TransactionFileLoader = ({asset, loader, reload=()=>{}}) => {
    const dispatch = useDispatch();

    const uploadFile = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: {
                'Content-Type': "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem(TOKEN)}`,
            },
            withCredentials: true,
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                // setProgress(percent);
                // if (percent === 100) {
                //     setTimeout(() => setProgress(0), 1000);
                // }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        fmData.append("reportFile", file);
        try {
            const res = await axios.post(
                `${API_BASE_URL}${LOADERS_BASE_PATH}/assets/${asset.id}/upload`,
                fmData,
                config
            );

            onSuccess(res.data);
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const props = {
        name: 'reportFile',
        accept : ['.xls', '.xlsx'],
        multiple: true,
        maxCount: 12,
        // customRequest: uploadFile,
        action: `${API_BASE_URL}${LOADERS_BASE_PATH}/assets/${asset.id}/file-upload`,
        withCredentials: true,
        headers: {
            authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        },
        beforeUpload: (file) => {
            const isExcel = file.type === 'application/vnd.ms-excel' ||
                file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                message.error(`${file.name} is not a excel file`);
            }
            return isExcel || Upload.LIST_IGNORE;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                // addReportTransactions(info.file.response, dispatch);
                reload();
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return(
        <div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <Icon fontSize={20} icon='material-symbols:upload'/>
                </p>
                <p className="ant-upload-text">Перетащите сюда файлы отчетов брокера для загрузки</p>
                {/*<p className="ant-upload-hint">*/}
                {/*    Support for a single or bulk upload. Strictly prohibited from uploading company data or other*/}
                {/*    banned files.*/}
                {/*</p>*/}
            </Dragger>
        </div>
    )
}

export default TransactionFileLoader;
