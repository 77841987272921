import React, {useEffect, useState} from 'react';

import {Button} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {
    loadAsyncTransactions,
    loadUploadedTransactionsAsync, loadReportErrorTransactionsAsync,
    removeReportErrorTransaction, setCreatingTransaction, removeReportErrorTransactionAsync
} from "../transactionsSlice";

import './BrokerReportLoading.less';
import ReportTransactionsViewer from "./ReportTransactionsViewer";
import TransactionFileLoader from "./TransactionFileLoader";
import TransactionApiLoader from "./TransactionApiLoader";
import ReportErrorTransactionsViewer from "./ReportErrorTransactionsViewer";



class LoaderFactory {
    static build(asset, loader, reloadTransactions)
    {
        const {type} = loader;
        switch (type) {
            case 'fileReport':
                return <TransactionFileLoader asset={asset} loader={loader} reload={reloadTransactions}/>;
            case 'tokenApiReport':
                return <TransactionApiLoader asset={asset} loader={loader} reload={reloadTransactions}/>;
            default:
                return undefined;
        }
    }
}

const BrokerReportLoading = ({asset}) => {

    const newTransactionIDs = () => {
        return reportTransactions.filter( i => i.status === 'NEW' || i.status === 'LOADING' ).map(i => i.id);
    }

    const dispatch = useDispatch();
    const reportTransactions = useSelector(state => state.transactions.currentAsset.reportTransactions);
    const reportErrorTransactions = useSelector(state => state.transactions.currentAsset.reportErrorTransactions);
    const [checkedList, setCheckedList] = useState(newTransactionIDs());
    const [checkAll, setCheckAll] = useState(newTransactionIDs().length === checkedList.length);
    const [indeterminate, setIndeterminate] = useState(checkedList.length > 0 && checkedList.length < newTransactionIDs().length);
    const [refreshInterval, setRefreshInterval] = useState(0);

    const currencies = useSelector(state => state.currencies.currencies)
    const editingTransaction = useSelector(state => state.transactions.editingTransaction);
    const openEditingDialog = useSelector(state => state.transactions.openEditingDialog);


    const {id: assetId} = asset;
    useEffect(() => {
        dispatch(loadUploadedTransactionsAsync({assetId}));
        dispatch(loadReportErrorTransactionsAsync({assetId}));
    }, [assetId, dispatch]);


    useEffect(() => {
        setCheckAll(newTransactionIDs().length === checkedList.length);
        setIndeterminate(checkedList.length > 0 && checkedList.length < newTransactionIDs().length);
    }, [checkedList.length, reportTransactions.length]);


    useEffect(() => {
        if (reportTransactions.filter(wrapper => wrapper.status === 'LOADING').length === 0) {
            console.log('Все транзакции отработаны.');
            setRefreshInterval(0);
            const {pagination = {}} = asset;
            dispatch(loadAsyncTransactions({asset, pagination}));
        }
    }, [reportTransactions, dispatch]);

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(() => {
                console.log('Обновление транзаций.');
                dispatch(loadUploadedTransactionsAsync({assetId}));
                dispatch(loadReportErrorTransactionsAsync({assetId}));
            }, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refreshInterval, dispatch]);

    const reloadTransactions = () => {
        dispatch(loadUploadedTransactionsAsync({assetId}));
        dispatch(loadReportErrorTransactionsAsync({assetId}));
        setRefreshInterval(3000);
    }

    const loadersRenderer = (asset) => {
        const {broker: {reportLoaderTypes = []}} = asset;
        return reportLoaderTypes.map( loader => LoaderFactory.build(asset, loader, reloadTransactions))
    }


    const reportTransactionsNew = reportTransactions.filter(i => i.status === 'NEW' || i.status === 'LOADING');

    return (
        <div className='ip-broker-report-loader'>
            {loadersRenderer(asset)}
            <ReportTransactionsViewer key='new-report-transactions'
                caption='Новые'
                isCheckColumn={true}
                reportTransactions={reportTransactionsNew}
                checkedList={checkedList}
                checkAll={checkAll}
                indeterminate={indeterminate}
                onHeaderCheckboxHandler={(e) => {
                    if (e.target.checked)
                        setCheckedList(newTransactionIDs());
                    else
                        setCheckedList([]);
                }}
                onCheckboxHandler={(e) => {
                    const {checked, value} = e.target;
                    if (checked)
                        setCheckedList([...checkedList, value]);
                    else
                        setCheckedList(checkedList.filter(i => i !== value));
                }}
            />
            <ReportErrorTransactionsViewer key='errors-report-transactions'
                caption='Ошибки'
                reportTransactions={reportErrorTransactions}
                onCreateTransaction={(transactionData) => {
                    dispatch(setCreatingTransaction(transactionData));
                }}
               onRemoveErrorTransaction={(wrapperId) => {
                   dispatch(removeReportErrorTransactionAsync({assetId, wrapperId}))
               }}
            />
            <ReportTransactionsViewer key='loaded-report-transactions'
                caption='Загруженные'
                reportTransactions={reportTransactions.filter(i => i.status === 'DONE' || 'ERROR')}
            />
        </div>
    )
}

export default BrokerReportLoading;
