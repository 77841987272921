import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    transactions,
    transaction,
    createTransaction,
    updateTransaction,
    deleteTransaction,
    assetDetailInfo, prepareFileUpload, getLoadedTransactions,
    saveTransactions, updateReportLoaderParams, assetTotalReturn, getReportLoadHistoryItems,
    getErrorTransactions, deleteErrorTransactions
} from '../../services/apiService'

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        currentAsset: {
            transactions: [],
            reportTransactions: [],
            reportErrorTransactions: [],
            reportLoadHistoryItems: []
        },
        loading: false,
        editingTransaction: {},
        openEditingDialog: false,
        filteredInfo: {}
    },
    reducers: {
        currentAsset: (state, {payload}) => {
            state.currentAsset = {
                reportTransactions: [],
                reportErrorTransactions: [],
                filteredInfo: {},
                ...payload
            };
        },
        setCreatingTransaction: (state,{payload}) => {
            if(payload)
                state.editingTransaction = payload;
            else
                state.editingTransaction = {};
            state.openEditingDialog = true;
        },
        closeEditingDialog: (state) => {
            state.openEditingDialog = false;
        },
        reportTransactions: (state, {payload}) => {
            state.currentAsset = {
                ...state.currentAsset,
                reportTransactions: [
                    ...payload
                ]
            }
        },
        removeReportErrorTransaction: (state, {payload}) => {
            const reportErrorTransactions = state.currentAsset.reportErrorTransactions.filter(
                wrapper => {
                    const {id} = wrapper;
                    return id !== payload;
                }
            )
            state.currentAsset = {
                ...state.currentAsset,
                reportErrorTransactions
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAsyncTransactions.pending, (state, action) => {
                const {filters: filteredInfo } = action.meta.arg;
                state.currentAsset = {
                    ...state.currentAsset,
                    filteredInfo
                };
                state.loading = true;
            })
            .addCase(loadAsyncTransactions.fulfilled, (state, action) => {
                const {content: transactions = [], pageable: page, totalElements} = action.payload;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber + 1
                };

                state.currentAsset = {
                    ...state.currentAsset,
                    transactions,
                    pagination
                };
                state.loading = false;
            })
            .addCase(loadAsyncTransactions.rejected, (state, action) => {
                state.loading = false;
                state.currentAsset = {
                    ...state.currentAsset,
                    transactions: []
                };
            })
            .addCase(loadTransactionAsync.fulfilled, (state, action) => {
                state.editingTransaction = action.payload;
                state.openEditingDialog = true;
            })
            .addCase(createTransactionAsync.fulfilled, (state, action) => {
                const {idWrapper} = state.editingTransaction;
                const reportErrorTransactions = state.currentAsset.reportErrorTransactions.filter(
                    wrapper => {
                        const {id} = wrapper;
                        return id !== idWrapper;
                    }
                )
                state.currentAsset = {
                    ...state.currentAsset,
                    transactions: [
                        action.payload,
                        ...state.currentAsset.transactions
                    ],
                    reportErrorTransactions
                };
                state.openEditingDialog = false;
            })
            .addCase(updateTransactionAsync.fulfilled, (state, action) => {
                let transactions = state.currentAsset.transactions.map(
                    transaction => transaction.id === action.payload.id ? action.payload : transaction
                );

                state.currentAsset = {
                    ...state.currentAsset,
                    transactions
                };
                state.openEditingDialog = false;
            })
            .addCase(deleteTransactionAsync.fulfilled, (state, action) => {
                const {id} = action.meta.arg;
                let transactions = state.currentAsset.transactions.filter(
                    transaction => transaction.id !== id
                );

                state.currentAsset = {
                    ...state.currentAsset,
                    transactions
                };
            })
            .addCase(removeReportErrorTransactionAsync.fulfilled, (state, action) => {
                const {wrapperId} = action.meta.arg;
                const reportErrorTransactions = state.currentAsset.reportErrorTransactions.filter(
                    wrapper => {
                        const {id} = wrapper;
                        return id !== wrapperId;
                    }
                )
                state.currentAsset = {
                    ...state.currentAsset,
                    reportErrorTransactions
                };
            })
            .addCase(loadAssetDetailInfoAsync.fulfilled, (state, action) => {
                state.currentAsset = {
                    ...state.currentAsset,
                    detailInfo: action.payload
                }
            })
            .addCase(currentAssetTotalReturnAsync.fulfilled, (state, action) => {
                state.currentAsset = {
                    ...state.currentAsset,
                    totalReturn: action.payload
                }
            })
            .addCase(loadUploadedTransactionsAsync.fulfilled, (state, action) => {
                state.currentAsset = {
                    ...state.currentAsset,
                    reportTransactions: [
                        ...action.payload
                    ]
                }
            })
            .addCase(loadReportErrorTransactionsAsync.fulfilled, (state, action) => {
                state.currentAsset = {
                    ...state.currentAsset,
                    reportErrorTransactions: [
                        ...action.payload
                    ]
                }
            })
            .addCase(getReportLoadHistoryItemsAsync.fulfilled, (state, action) => {
                state.currentAsset = {
                    ...state.currentAsset,
                    reportLoadHistoryItems: [
                        ...action.payload
                    ]
                }
            })
            .addCase(saveTransactionsAsync.pending, (state, action) => {
                const {transactions} = action.meta.arg;
                const reportTransactions = state.currentAsset.reportTransactions.map(
                    item => {
                        if( transactions.includes(item.id)) {
                            // console.log(item.id);
                            return {
                                ...item,
                                status: 'LOADING'
                            }
                        }
                        else
                            return item;
                    });
                state.currentAsset = {
                    ...state.currentAsset,
                    reportTransactions: reportTransactions
                }
            })
    }
})


export const { currentAsset, setCreatingTransaction, closeEditingDialog, removeReportErrorTransaction } = transactionsSlice.actions

export const loadAsyncTransactions = createAsyncThunk('transactions/loadAsyncTransactions', ({asset, pagination, filters}) => {
    const {id} = asset;
    return transactions(id, pagination, filters);
})

export const setCurrentAsset = ( asset, dispatch) => {
    const pagination = {
        showSizeChanger:false,
        total: 0,
        pageSize: 20,
        current: 0
    };
    const updatedAsset = {
        pagination,
        ...asset
    }
    dispatch(currentAsset(updatedAsset));
    dispatch(loadAsyncTransactions({asset: updatedAsset, pagination}));
}


export const currentAssetTotalReturnAsync = createAsyncThunk('transactions/currentAssetTotalReturnAsync', ({assetId}) => {
    return assetTotalReturn(assetId);
})

export const loadUploadedTransactionsAsync = createAsyncThunk('transactions/loadUploadedTransactionsAsync', ({assetId}) => {
    return getLoadedTransactions(assetId);
})

export const loadReportErrorTransactionsAsync = createAsyncThunk('transactions/loadReportErrorTransactionsAsync', ({assetId}) => {
    return getErrorTransactions(assetId);
})

export const removeReportErrorTransactionAsync = createAsyncThunk('transactions/removeReportErrorTransactionAsync', ({assetId, wrapperId}) => {
    return deleteErrorTransactions(assetId, wrapperId);
})

export const getReportLoadHistoryItemsAsync = createAsyncThunk('transactions/getReportLoadHistoryItemsAsync', ({assetId}) => {
    return getReportLoadHistoryItems(assetId);
})

export const saveTransactionsAsync = createAsyncThunk('transactions/saveTransactionsAsync', ({assetId, transactions}) => {
    return saveTransactions(assetId, transactions);
})

export const loadTransactionAsync = createAsyncThunk('transactions/loadTransactionAsync', ({asset, id}) => {
    const {id: assetId} = asset;
    return transaction(assetId, id);
})

export const createTransactionAsync = createAsyncThunk('transactions/createTransactionAsync', ({asset, transaction},thunkApi) => {
    const {id: assetId} = asset;
    const {dispatch} = thunkApi;
    const {editingTransaction:{idWrapper}} = thunkApi.getState().transactions;

    return createTransaction(assetId, transaction)
        .then(response => {

            if(idWrapper)
                dispatch(removeReportErrorTransactionAsync({assetId, wrapperId: idWrapper}));
            return response;
        });
})

export const updateTransactionAsync = createAsyncThunk('transactions/updateTransactionAsync', ({asset, transaction}) => {
    const {id: assetId} = asset;
    return updateTransaction(assetId, transaction);
})

export const deleteTransactionAsync = createAsyncThunk('transactions/deleteTransactionAsync', ({asset, id}) => {
    const {id: assetId} = asset;
    return deleteTransaction(assetId, id);
})

export const loadAssetDetailInfoAsync = createAsyncThunk('transactions/loadAssetDetailInfoAsync', ({asset}) => {
    return assetDetailInfo(asset);
})


export default transactionsSlice.reducer
