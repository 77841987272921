import React from 'react';
import {Button, Checkbox, Divider, Popconfirm} from "antd";
import moment from "moment";
import DetailTable from "../../../components/detailtable/DetailTable";
import {DetailInfoWrapper} from "./DetailInfoWrapper";
import {Icon} from "@iconify/react";

const ReportErrorTransactionsViewer = ({
    reportTransactions,
    caption,
    onCreateTransaction = (initTransactionData) => {},
    onRemoveErrorTransaction = (wrapperId) => {},
}) => {

    if (reportTransactions.length > 0)
        return (
            <DetailInfoWrapper caption={caption}>
                <DetailTable>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Сделка</th>
                        <th>Сумма</th>
                        <th>Детали</th>
                        <th>Ошибка</th>
                        <th>Статус</th>
                        <th>...</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reportTransactions.map(wrapper => {
                        const {transaction = {}, status, id: idWrapper, error} = wrapper;
                        const {id, edate, currency, total, description, details, transactionType } = transaction;
                        return (
                            <tr key={`${idWrapper}`}>
                                <td>{moment(edate).format('DD.MM.YYYY HH:mm:ss')}</td>
                                <td>{description}</td>
                                <td align='right'>
                                    {total.toLocaleString('ru-RU', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency
                                    })}
                                </td>
                                <td>{details}</td>
                                <td>{error}</td>
                                <td>{status}</td>
                                <td>
                                    <div className='action-panel'>
                                        <Button type='link'
                                            onClick={() => {
                                                onCreateTransaction({
                                                    transactionType,
                                                    edate,
                                                    total,
                                                    currency,
                                                    idWrapper
                                                })
                                            }}>
                                            <Icon fontSize={14} icon='material-symbols:edit-outline'/>
                                        </Button>
                                        <Divider type="vertical"/>
                                        <Popconfirm
                                            title='Вы уверены что хотите удалить ошибку?'
                                            onConfirm={()=>{onRemoveErrorTransaction(idWrapper)}}
                                            okText="Yes"
                                            cancelText="No"
                                        >

                                            <Button type='link' >
                                                <Icon fontSize={14} icon='material-symbols:delete-outline'/>
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </DetailTable>
            </DetailInfoWrapper>
        );
}

export default ReportErrorTransactionsViewer;
